body {
    padding: 0;
    margin: 0;
    font-family: 'Open Sans', sans-serif !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    color: #233659 !important;
    font-size: 18px;
    background-color: white !important;
  }
  h1 {
    font-size: 2.0em !important;
    letter-spacing: 1px;
    font-weight: bolder !important;
    line-height: normal !important;
  }
  h2 {
    font-size: 1.8em !important;
    font-weight: bolder !important;
    line-height: normal !important;
  }
  h3 {
    font-size: 1.3em !important;
    font-weight: bolder !important;
    line-height: normal !important;
  }
  h4 {
    font-size: 1.2em !important;
    font-weight: bolder !important;
    line-height: normal !important;
  }
  h5 {
    font-size: 1.1em !important;
    font-weight: bolder !important;
    line-height: normal !important;
  }
  h6 {
    font-size: 1.0em !important;
    font-weight: bolder !important;
    line-height: normal !important;
  }
  input {
    -webkit-appearance: none !important;
  }
  .chota-divider {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  .input-label {
    color: gray;
    margin-bottom: 5px;
    margin-left: 2px;
  }

  .our-box-shadow {
      -webkit-box-shadow: 0px 0px 3px 0px rgba(115,93,208,0.5);
      -moz-box-shadow: 0px 0px 3px 0px rgba(115,93,208,0.5);
      box-shadow: 0px 0px 3px 0px rgba(115,93,208,0.5);
    }
  /* Default posts -- complete list style */
  .default-posts-ul {
      list-style: none;
      padding: 0px;
      margin: 0px;
  }
    /* Default posts -- style of a single post box */
  .default-posts-li {
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 5px;
      cursor: pointer;
      border: 1px dotted #a29bfe;
      user-select: none;
      -moz-user-select: none;
      box-shadow: 1px 1px 4px #dfe6e9;
  }

  .react-tel-input input {
    width: 100% !important;
  }

  .rs-modal {
    max-width: 90% !important;
  }

  /* Chrome, Safari, Edge, Opera */
  .code-input::-webkit-outer-spin-button,
  .code-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;
  }

  .chota-divider {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

.aw-title {
  font-size: 2.6em !important;
}

.list-table {
  min-width: 100%;
  width: 100%;
  max-width: 100%;
  overflow: auto;
}

.list-table-thead {
  border-bottom: 1px solid gray;
}
.table-head-column {
  color: gray;
  font-weight: bold;
  font-size: 12px;
}

.row-tbody:hover {
  background-color: #f5f5f5;
}

.table-column {
  min-width: 150px;
  text-align: left;
  padding: 10px;
  overflow: auto;
  border-bottom: 1px solid #273c7528;
}

.big-text {
  font-size: 18px !important;
}

.video_wrapper_16-9 {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  border-radius: 10px;
  position: relative;
  padding-bottom: 56.25%; /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */
}
.video_wrapper_16-9 iframe {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}



.check-list {
  margin: 0;
  padding: 0;
  padding-left: 1.2rem;
}

.check-list li {
  position: relative;
  list-style-type: none;
  padding-left: 2.5rem;
  margin-bottom: 0.5rem;
}

.check-list li:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: -2px;
    width: 5px;
    height: 11px;
    border-width: 0 2px 2px 0;
    border-style: solid;
    border-color: #00a8a8;
    transform-origin: bottom left;
    transform: rotate(45deg);
}

.registrationContainer{
  display: 'flex';
min-height: 100vh;
overflow: hidden;
    flex-direction: 'column';

}

.aw-box-shadow {
  box-shadow: 0 15px 35px 0 rgb(60 66 87 / 8%), 0 5px 15px 0 rgb(0 0 0 / 12%);
}

@media (max-width:420px){
  .registrationContainer{
    overflow-y: auto !important;
  }
  .registrationHalfContainer{
    height: 100% !important;
    overflow-y: inherit !important;
  }
}


.main-body {
  width: 100%;

}

.header-area {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 3;
  background-color: white;
  padding: 10px;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px; */
  /* box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px; */
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.input-box-pappu {
  box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.9) 0px 0px 0px 1px;
}

.content-bay {
  z-index: 5;
  background-color: white;
  padding: 5px;
  padding-bottom: 50px;
  overflow-x: auto;
  display: flex;
  justify-content: center;
}

.content-bay-grid {
  border-radius: 10px;
  padding: 10px;
  margin-top: 20px;
  max-width: 98%;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px; */
  /* box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px; */
  /* box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.9) 0px 0px 0px 1px; */
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

@media only screen and (max-width: 600px) {
  .content-bay {
    z-index: 5;
    background-color: white;
    margin: 10px;
  }
  .content-bay-grid {
    width: 100%;
  }
}


.p-10 {
  font-size: 12px;
}

.dr-header {
  height: 50px !important;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 2;
}

.dr-body {
  flex: 1;
  min-height: 0px;
  display: flex;
  /* padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 30px;
  margin-top: 10px; */
}

.main-menu-ul {
  display: inline-block;
  list-style: none;
  padding: 0px;
  margin: 0px;
  margin-left: 20px;
}

.main-menu-li {
  margin-left: 20px;
  display: inline;
  font-size: 14px;
  color: gray;
  letter-spacing: 1px;
  cursor: pointer;
}

.main-menu-li:hover {
  color: #273c75a2;
}
.main-menu-li-active:hover {
  color: #273c75;
}

.main-menu-li-active {
  color: #273c75;
  font-weight: bolder;
}

.table-head {
  border: 1px solid rgba(244, 244, 247, 0.521);
  padding: 10px;
}

.rs-nav-item .rs-nav-item-content {
  font-size: 12px !important;
}

.our-card {
  background-color: #f5f5f5;
  /* border: 1px solid #fafaf6; */
  padding: 10px;
  /* width: 100%; */
  border-radius: 5px;
  /* box-shadow: 0 7px 30px -10px rgba(150,170,180,0.5); */
}

.our-card-body {
  padding: 10px;
}

.light-heading {
  color: gray;
}

/* .file-item{
  display:flex;
  flex-direction: row;

} */
.file-item:hover{
  cursor: pointer;
  background-color:#f7f7fa;
}

.content-area-beebuzz {
  margin-left: 15%;
  margin-right: 15%;
}

.session-iframe-video {
  -webkit-box-shadow: 0px 0px 6px -1px rgb(196, 196, 196);
  -moz-box-shadow: 0px 0px 6px -1px rgb(196, 196, 196);
  box-shadow: 0px 0px 6px -1px rgb(196, 196, 196);
  border-radius: 5px;
}

@media screen and (max-width: 500px) {
  .content-area-beebuzz {
    margin-left: 2%;
    margin-right: 2%;
    width: '100%';
  }
}

.rs-picker-input {
  padding-bottom: 5px !important;
}

.rs-radio-wrapper .rs-radio-inner::before {
  border: 2px solid black;
}

.thread-card {
  display: flex;
  flex-direction: column;
  width: fit-content;
  border: 1px solid silver;
  border-radius: 10px;
  max-width: 300px;
  -webkit-box-shadow: 0px 0px 6px -1px rgb(196, 196, 196);
  -moz-box-shadow: 0px 0px 6px -1px rgb(196, 196, 196);
  box-shadow: 0px 0px 6px -1px rgb(196, 196, 196);
}

.thread-card-image {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  opacity: 0.8;
}

.thread-card-image-container {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: black;
}

.thread-card-button-container {
  padding: 5px;
}

.aws-btn {
  height: auto !important;
}

.check-all-grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 100%;
  overflow: auto;
  /* grid-template-columns: repeat(); */
}

/* For Mobile Devices */
@media only screen and (max-width: 600px) {
  .check-all-grid {
    justify-content: left;
  }
  .check-all-grid-option {
    min-width: 95%;
    max-width: 95%;
    overflow: auto;
  }
}

.check-all-grid-option {
  margin: 10px;
  /* margin-bottom: 10px; */
  padding: 5px;
  border: 1px solid #273c75;
  border-radius: 5px;
  padding-right: 15px;
}

.local-editor-for-drawer .ProseMirror {
  overflow: unset !important;
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .blurred-container {
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
  }
}

/* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .blurred-container {
    background-color: rgba(255, 255, 255, .8);
  }
}
